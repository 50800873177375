<template>
    <el-dialog
        class="job-match-continue-dialog"
        :visible.sync="dialogVisible"
        width="500px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <div class="job-match-continue">
            <div class="recommend-info">
                正在推荐候选人
                <!-- <a
                    class="text-primary"
                    :href="`/Headhunting/MyCompany.html#/candidateDetail/${candidateId}`"
                    target="_blank"
                >
                    {{candidateName}}
                </a> -->
                <a
                    class="text-primary"
                    :href="`/#/candidateDetail/${candidateId}`"
                    target="_blank"
                >
                    {{candidateName}}
                </a>
                到
                <!-- <a
                    class="text-primary"
                    :href="`/Headhunting/MyCompany.html#/Job/${jobId}`"
                    target="_blank"
                >
                    {{jobDetail.name}}[{{jobDetail.location}}]
                </a> -->
                <a
                    class="text-primary"
                    :href="`/#/Job/${jobId}`"
                    target="_blank"
                >
                    {{jobDetail.name}}[{{jobDetail.location}}]
                </a>
            </div>
            <div class="candidate-inquiry-tip">
                <p class="tip-context">未确认候选人求职意愿直接推荐的，属于「盲推」。盲推行为会给用人单位和候选人带来困扰，猎必得严格杜绝该行为。</p>
                <!-- <el-button type="primary" @click="goToUrl(`/Headhunting/MyCompany.html#/candidateDetail/${candidateId}`)">去寻访</el-button> -->
                <el-button type="primary" @click="goToUrl(`/#/candidateDetail/${candidateId}`)">去寻访</el-button>
                <p class="tip-context">
                    已寻访？
                    <span
                        class="text-operate-btn green"
                        :class="isShowjobCharge && !jobChargeAgree ? 'disabled' : ''"
                        @click="continueRecommend"
                    >
                        继续推荐>>
                    </span>
                </p>
            </div>
            <div class="payment-refund" v-if="isShowjobCharge">
                <div class="payment-refund-header">
                    <el-checkbox
                        class="payment-refund-checkbox"
                        v-model="jobChargeAgree"
                    >
                        同意《付款退款规则》
                    </el-checkbox>
                    <span
                        class="text-operate-btn grey read-detail-rule"
                        :class="isShowJobChargeRule ? 'show-job-charge-rule' : ''"
                        @click="isShowJobChargeRule = !isShowJobChargeRule"
                    >
                        阅读详情
                    </span>
                </div>
                <div class="job-charge-rule" v-if="isShowJobChargeRule">
                    <p class="job-charge-rule-item">
                        《付款约定》<br/>
                        入职付款比例 {{jobRefundPolicy.onboardPaymentPropotion}}%， 过保时付款比例为 {{jobRefundPolicy.overProtectionPaymentPropotion}}%
                    </p>
                    <p class="job-charge-rule-item">
                        《保证期退款条款》<br/>
                        {{jobRefundPolicy.refundPolicyDescription}}
                    </p>
                    <p class="job-charge-rule-tip">
                        注: 如和线下合同冲突，以线上公司设定为准
                    </p>
                    <el-checkbox
                        class="payment-refund-checkbox"
                        v-model="jobChargeAgree"
                    >
                        同意《付款退款规则》
                    </el-checkbox>
                </div>
                <div class="job-charge-footer">
                    <el-button
                        type="primary"
                        :disabled="isShowjobCharge && !jobChargeAgree"
                        @click="continueRecommend"
                    >
                        继续推荐
                    </el-button>
                </div>
            </div>
        </div>
    </el-dialog>
</template>
<script>
export default {
    name: "jobMatchContinueDialog",
    props: {
        candidateName: String,
        jobDetail: Object
    },
    data(){
        return{
            candidateId: "",
            jobId: "",
            dialogVisible: false,
            jobRefundPolicy: {
                onboardPaymentPropotion: 0,
                overProtectionPaymentPropotion: 0,
                refundPolicyDescription: ""
            },
            jobChargeAgree: false,
            isShowjobCharge: false,
            isShowJobChargeRule: false
        }
    },
    mounted() {},
    methods:{
        getIntelligentRecommend() {
            _request({
                url: "/Headhunting/Recommendation/GetIntelligentRecommendDialog",
                method: "GET",
                data: {
                    jobId: this.jobId,
                    candidateId: this.candidateId
                },
                throwBusinessError: true
            }).then(res => {
                if(!res.finishedCircuit) {
                    if(!res.hasOrdered && !res.hasInqueryOrRecommendation && res.jobRefundPolicy) {
                        this.isShowjobCharge = true;
                    }
                    if(res.jobRefundPolicy) {
                        res.jobRefundPolicy.overProtectionPaymentPropotion = res.jobRefundPolicy.overProtectionPaymentPropotion < 30 ? 25 : res.jobRefundPolicy.overProtectionPaymentPropotion;
                        res.jobRefundPolicy.onboardPaymentPropotion = res.jobRefundPolicy.onboardPaymentPropotion > 70 ? 75 : res.jobRefundPolicy.onboardPaymentPropotion;
                        this.jobRefundPolicy = res.jobRefundPolicy;
                    }
                    if(!res.hasOrdered || !res.hasInqueryOrRecommendation) {
                        this.dialogVisible = true;
                    }
                }
            }).catch(err => {});
        },
        continueRecommend() {
            if(this.isShowjobCharge && !this.jobChargeAgree) {
                return false;
            } else {
                this.getOrder();
            }
        },
        getOrder() {
            _request({
                url: "/Headhunting/Order/GetInternalOrder",
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    jobId: this.jobId,
                    confirm: true,
                    __RequestVerificationToken: this.$store.state.verificationToken.verificationToken
                },
                throwBusinessError: true
            }).then(res => {
                this.dialogVisible = false;
            }).catch(err => {
                this.dialogVisible = false;
            });
        },
        show(jobId, candidateId) {
            this.jobId = jobId;
            this.candidateId = candidateId;
            this.getIntelligentRecommend();
        },
        goToUrl(url) {
            window.open(url);
        }
    }
}
</script>
<style lang="scss" scoped>
.text-primary {
    color: $primary;
}
.job-match-continue {
    .recommend-info,
    .candidate-inquiry-tip {
        text-align: center;
    }
    .recommend-info {
        font-size: 16px;
        color: #333;
        margin-bottom: 14px;
    }
    .candidate-inquiry-tip {
        margin-bottom: 10px;
        border: 1px solid #e1e1e1;
        border-radius: 5px;
        padding: 15px 22px;
        line-height: 24px;
        color: #666;
        display: flex;
        flex-direction: column;
        align-items: center;
        .tip-context,
        .el-button {
            margin-bottom: 18px;
        }
    }
    .payment-refund {
        line-height: 24px;
        .payment-refund-header {
            display: flex;
            justify-content: space-between;
            color: #b9b9b9;
            .payment-refund-checkbox.el-checkbox {
                color: #b9b9b9;
            }
            .read-detail-rule {
                padding-right: 20px;
                position: relative;
                &:after {
                    content: "";
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    width: 9px;
                    height: 9px;
                    border: 2px solid #b9b9b9;
                    border-left: none;
                    border-top: none;
                    transform: rotate(45deg);
                }
                &.show-job-charge-rule {
                    &::after {
                       top: 10px;
                        transform: rotate(-135deg);
                    }
                }
            }
        }
        .job-charge-rule {
            padding: 0 20px 10px;
            margin: 26px 0 10px;
            cursor: text;
            color: #666;
            border-top: 1px dashed #e1e1e1;
            border-radius: 6px;
            .job-charge-rule-tip {
                &::before {
                    content: "*";
                    color: #ff7200;
                }
            }
        }
        .job-charge-footer {
            text-align: center;
        }
    }
}
</style>

<style lang="scss">
.job-match-continue-dialog {
    .el-dialog__wrapper .el-dialog__header {
        border-bottom: none;
        height: 44px;
        line-height: 44px;
        .el-dialog__headerbtn {
            font-size: 20px;
            top: 16px;
            right: 16px;
        }
    }
}
.payment-refund-checkbox.el-checkbox {
    margin-bottom: 0;
    .el-checkbox__input .el-checkbox__inner {
        width: 18px;
        height: 18px;
        &::after {
            width: 4px;
            height: 10px;
            left: 5px;
            border-width: 2px;
        }
    }
}
</style>