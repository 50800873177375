var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "job-match-continue-dialog",
      attrs: {
        visible: _vm.dialogVisible,
        width: "500px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "job-match-continue" }, [
        _c("div", { staticClass: "recommend-info" }, [
          _vm._v("\n            正在推荐候选人\n            "),
          _c(
            "a",
            {
              staticClass: "text-primary",
              attrs: {
                href: `/#/candidateDetail/${_vm.candidateId}`,
                target: "_blank",
              },
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.candidateName) +
                  "\n            "
              ),
            ]
          ),
          _vm._v("\n            到\n            "),
          _c(
            "a",
            {
              staticClass: "text-primary",
              attrs: { href: `/#/Job/${_vm.jobId}`, target: "_blank" },
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.jobDetail.name) +
                  "[" +
                  _vm._s(_vm.jobDetail.location) +
                  "]\n            "
              ),
            ]
          ),
        ]),
        _c(
          "div",
          { staticClass: "candidate-inquiry-tip" },
          [
            _c("p", { staticClass: "tip-context" }, [
              _vm._v(
                "未确认候选人求职意愿直接推荐的，属于「盲推」。盲推行为会给用人单位和候选人带来困扰，猎必得严格杜绝该行为。"
              ),
            ]),
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.goToUrl(`/#/candidateDetail/${_vm.candidateId}`)
                  },
                },
              },
              [_vm._v("去寻访")]
            ),
            _c("p", { staticClass: "tip-context" }, [
              _vm._v("\n                已寻访？\n                "),
              _c(
                "span",
                {
                  staticClass: "text-operate-btn green",
                  class:
                    _vm.isShowjobCharge && !_vm.jobChargeAgree
                      ? "disabled"
                      : "",
                  on: { click: _vm.continueRecommend },
                },
                [_vm._v("\n                    继续推荐>>\n                ")]
              ),
            ]),
          ],
          1
        ),
        _vm.isShowjobCharge
          ? _c("div", { staticClass: "payment-refund" }, [
              _c(
                "div",
                { staticClass: "payment-refund-header" },
                [
                  _c(
                    "el-checkbox",
                    {
                      staticClass: "payment-refund-checkbox",
                      model: {
                        value: _vm.jobChargeAgree,
                        callback: function ($$v) {
                          _vm.jobChargeAgree = $$v
                        },
                        expression: "jobChargeAgree",
                      },
                    },
                    [
                      _vm._v(
                        "\n                    同意《付款退款规则》\n                "
                      ),
                    ]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "text-operate-btn grey read-detail-rule",
                      class: _vm.isShowJobChargeRule
                        ? "show-job-charge-rule"
                        : "",
                      on: {
                        click: function ($event) {
                          _vm.isShowJobChargeRule = !_vm.isShowJobChargeRule
                        },
                      },
                    },
                    [_vm._v("\n                    阅读详情\n                ")]
                  ),
                ],
                1
              ),
              _vm.isShowJobChargeRule
                ? _c(
                    "div",
                    { staticClass: "job-charge-rule" },
                    [
                      _c("p", { staticClass: "job-charge-rule-item" }, [
                        _vm._v("\n                    《付款约定》"),
                        _c("br"),
                        _vm._v(
                          "\n                    入职付款比例 " +
                            _vm._s(
                              _vm.jobRefundPolicy.onboardPaymentPropotion
                            ) +
                            "%， 过保时付款比例为 " +
                            _vm._s(
                              _vm.jobRefundPolicy.overProtectionPaymentPropotion
                            ) +
                            "%\n                "
                        ),
                      ]),
                      _c("p", { staticClass: "job-charge-rule-item" }, [
                        _vm._v("\n                    《保证期退款条款》"),
                        _c("br"),
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.jobRefundPolicy.refundPolicyDescription
                            ) +
                            "\n                "
                        ),
                      ]),
                      _c("p", { staticClass: "job-charge-rule-tip" }, [
                        _vm._v(
                          "\n                    注: 如和线下合同冲突，以线上公司设定为准\n                "
                        ),
                      ]),
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "payment-refund-checkbox",
                          model: {
                            value: _vm.jobChargeAgree,
                            callback: function ($$v) {
                              _vm.jobChargeAgree = $$v
                            },
                            expression: "jobChargeAgree",
                          },
                        },
                        [
                          _vm._v(
                            "\n                    同意《付款退款规则》\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "job-charge-footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        disabled: _vm.isShowjobCharge && !_vm.jobChargeAgree,
                      },
                      on: { click: _vm.continueRecommend },
                    },
                    [_vm._v("\n                    继续推荐\n                ")]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }