var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "推荐候选人",
        visible: _vm.dialogVisible,
        width: "550px",
        "show-close": false,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "recommend-confirm" }, [
        _vm._v("\n        您确定将此候选人"),
        _c("br"),
        _c("span", { staticClass: "candidate-realname" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.candidateDetail.realName) +
              "\n        "
          ),
        ]),
        !_vm.candidateDetail.isFromAuthorization &&
        _vm.candidateDetail.isFromShare
          ? _c("span", { staticClass: "authorize-info" }, [
              _vm._v(
                "\n            (来自求分享-" +
                  _vm._s(
                    _vm.candidateDetail.tradingType === 0 ? "云币" : "分成"
                  ) +
                  ")\n        "
              ),
            ])
          : _vm._e(),
        _c("br"),
        _vm._v("\n        推荐至：\n        "),
        _c("span", { staticClass: "job-info" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.jobDetail.name) +
              "[" +
              _vm._s(_vm.jobDetail.location) +
              "]-" +
              _vm._s(_vm.jobDetail.firmShortName) +
              "\n        "
          ),
        ]),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.recommendCancel } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: { click: _vm.recommendCandidate },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }