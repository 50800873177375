<template>
    <el-dialog
        width="560px"
        :title="title"
        custom-class="select-city-dialog"
        :visible.sync="dialogVisible"
        v-loading="loading"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <province-city
            ref="provinceCity"
            :enable-multipe-selected="true"
            :multipe-limit="5"
            :grid-length="6"
            :hasPrimaryCity="hasPrimaryCity"
        ></province-city>
        <span slot="footer" class="dialog-footer">
            <el-button @click="cancelHandle">取消</el-button>
            <el-button type="primary" @click="confirmHandle">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import ProvinceCity from '#/component/common/province-city.vue';
export default {
    components: {
        ProvinceCity,
    },
    props: {
        title: {
            type: String,
            default: '选择城市（最多选择五项）'
        },
        hasPrimaryCity: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false,
            dialogVisible: false,
            selectedCity: []
        }
    },
    methods: {
        show() {
            this.dialogVisible = true;
        },
        selectCityOutside(code) {
            this.$refs.provinceCity.selectCityOutside(code);
        },
        confirmHandle() {
            this.selectedCity = this.$refs.provinceCity.selectedCity;
            this.$emit('city-selected');
            this.dialogVisible = false;
        },
        cancelHandle() {
            this.dialogVisible = false;
        }
    }
}
</script>

<style lang="scss" scope>
</style>
