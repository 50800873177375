<template>
    <el-dialog
        title="推荐候选人"
        :visible.sync="dialogVisible"
        width="550px"
        :show-close="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <div class="recommend-confirm">
            您确定将此候选人<br/>
            <span class="candidate-realname">
                {{candidateDetail.realName}}
            </span>
            <!-- <span class="authorize-info" v-if="candidateDetail.isFromAuthorization">
                (来自于 <span class="firmname">{{candidateDetail.authorizationSourceFirmName}}</span>，成单后需分成{{candidateDetail.proportion}}%给该公司)
            </span> -->
            <span class="authorize-info" v-if="!candidateDetail.isFromAuthorization && candidateDetail.isFromShare">
                (来自求分享-{{candidateDetail.tradingType === 0 ? '云币' : '分成'}})
            </span>
            <br/>
            推荐至：
            <span class="job-info">
                {{jobDetail.name}}[{{jobDetail.location}}]-{{jobDetail.firmShortName}}
            </span>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="recommendCancel">取消</el-button>
            <el-button type="primary" @click="recommendCandidate">确定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import debounce from "lodash/debounce";
export default {
    name: "recommendConfirmDialog",
    props: {
        candidateDetail: Object,
        jobDetail: Object
    },
    data() {
        return{
            dialogVisible: false,
            candidateId: "",
            jobId: ""
        }
    },
    methods:{
        show(){
            this.dialogVisible = true;
        },
        recommendCandidate: debounce(function() {
            this.$emit('recommend-candidate');
            this.dialogVisible = false;
        }, 300),
        recommendCancel() {
            this.$emit('recommend-cancel');
            this.dialogVisible = false;
        }
    }
}
</script>
<style lang="scss" scoped>
.recommend-confirm {
    line-height: 36px;
    .candidate-realname,
    .job-info {
        color: $primary;
        font-size: 18px;
    }
    .authorize-info {
        margin-left: 10px;
        color: #f98f33;
    }
}
</style>