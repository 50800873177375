var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "recommend-detail" },
    [
      _c(
        "div",
        { staticClass: "recommend-content" },
        [
          _c(
            "div",
            { staticClass: "recommend-title" },
            [
              _c("img", {
                attrs: {
                  src: require("@src/assets/images/candidateDetail/recommend.png"),
                  alt: "",
                },
              }),
              _c("span", { staticClass: "tip" }, [
                _vm._v(
                  "正在推荐" +
                    _vm._s(
                      _vm.candidateDetail.realName
                        ? "候选人 " + _vm.candidateDetail.realName + " "
                        : "新候选人"
                    ) +
                    "到"
                ),
              ]),
              _vm.jobDetail.name
                ? [
                    _c(
                      "span",
                      {
                        staticClass: "job-name main",
                        attrs: {
                          title: `${_vm.jobDetail.name}[${_vm.jobDetail.location}]`,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.jobDetail.name) +
                            "[" +
                            _vm._s(_vm.jobDetail.location) +
                            "]"
                        ),
                      ]
                    ),
                    _vm._v(" - "),
                    _c("span", { staticClass: "job-name sub" }, [
                      _vm._v(_vm._s(_vm.jobDetail.firmShortName)),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _vm.jobDetail.hasProjectManager
            ? _c("p", { staticClass: "recommend-pm-tip" }, [
                _vm._v(
                  "\n          提示：本职位有专属PM，需PM初筛通过后，才推荐给HR反馈\n      "
                ),
              ])
            : _vm._e(),
          _c("candidate-resume-view", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.candidateLoading,
                expression: "candidateLoading",
              },
            ],
            attrs: {
              candidateDetail: _vm.candidateDetail,
              isNeedComplete: _vm.isNeedEditBaseInfo,
              needEditStr: _vm.needEditStr,
              needEditArr: _vm.needEditArr,
            },
            on: { "edit-base-info": _vm.editBaseInfo },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "recommend-option" },
        [
          _c("div", { staticClass: "job-from" }, [
            _c(
              "div",
              { staticClass: "from-wrap" },
              [
                _c("span", { staticClass: "from-label" }, [_vm._v("来源渠道")]),
                _c(
                  "el-radio-group",
                  {
                    on: { change: _vm.radioChange },
                    model: {
                      value: _vm.jobFrom,
                      callback: function ($$v) {
                        _vm.jobFrom = $$v
                      },
                      expression: "jobFrom",
                    },
                  },
                  _vm._l(_vm.jobFromArray, function (rItem, index) {
                    return _c(
                      "el-radio",
                      {
                        key: "jobFrom_" + index,
                        attrs: { label: rItem.value },
                      },
                      [
                        _vm._v(
                          "\n                      " +
                            _vm._s(rItem.label) +
                            "\n                  "
                        ),
                      ]
                    )
                  }),
                  1
                ),
                _vm.jobFrom == "其它"
                  ? _c(
                      "div",
                      { staticClass: "other-input-wrap" },
                      [
                        _c("el-input", {
                          staticStyle: { width: "180px" },
                          attrs: { maxlength: "10", placeholder: "最多10字" },
                          model: {
                            value: _vm.otherContext,
                            callback: function ($$v) {
                              _vm.otherContext =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "otherContext",
                          },
                        }),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showOtherTip,
                                expression: "showOtherTip",
                              },
                            ],
                            staticClass: "others-tip",
                          },
                          [_vm._v("请输入渠道来源")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFromTip,
                  expression: "showFromTip",
                },
              ],
              staticClass: "from-tip",
              domProps: { textContent: _vm._s(_vm.jobFromTipText) },
            }),
          ]),
          _vm.isCFUser
            ? _c(
                "div",
                { staticClass: "job-from business-classfication-form" },
                [
                  _c(
                    "div",
                    { staticClass: "from-wrap" },
                    [
                      _c("span", { staticClass: "from-label" }, [
                        _vm._v("业务分类"),
                      ]),
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "请选择目标业务分类",
                          },
                          on: { change: _vm.changeSelectBusinessClass },
                          model: {
                            value: _vm.BusinessClassificationId,
                            callback: function ($$v) {
                              _vm.BusinessClassificationId = $$v
                            },
                            expression: "BusinessClassificationId",
                          },
                        },
                        _vm._l(_vm.businessClassificationList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showBusinessTip,
                        expression: "showBusinessTip",
                      },
                    ],
                    staticClass: "from-tip",
                    domProps: { textContent: _vm._s(_vm.jobBusinessTipText) },
                  }),
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "job-from city-form" }, [
            _c(
              "div",
              { staticClass: "from-wrap" },
              [
                _c("span", { staticClass: "from-label" }, [_vm._v("推荐城市")]),
                _c(
                  "div",
                  { staticClass: "hot-city" },
                  _vm._l(_vm.hotCityMap, function (city, key) {
                    return _c(
                      "span",
                      {
                        key: key,
                        on: {
                          click: function ($event) {
                            return _vm.selectHotCity(city.label, city.value)
                          },
                        },
                      },
                      [_vm._v(_vm._s(city.label))]
                    )
                  }),
                  0
                ),
                _c("el-input", {
                  staticClass: "col-8",
                  attrs: {
                    readOnly: "true",
                    placeholder: "请选择，最多选择5项",
                    value: _vm.locationName,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.showCitySelect.apply(null, arguments)
                    },
                  },
                }),
                _c("select-city-dialog", {
                  ref: "selectCityDialog",
                  attrs: { "has-primary-city": true },
                  on: { "city-selected": _vm.handleCitySelect },
                }),
              ],
              1
            ),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showCityTip,
                  expression: "showCityTip",
                },
              ],
              staticClass: "from-tip",
              domProps: { textContent: _vm._s(_vm.jobCityTipText) },
            }),
          ]),
          !_vm.isRecommended && !_vm.candidateLoading
            ? _c("candidate-recommend-form", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.recommendLoading,
                    expression: "recommendLoading",
                  },
                ],
                ref: "recommendForm",
                staticClass: "candidate-recommend-form",
                attrs: {
                  candidateId: _vm.candidateDetail.id,
                  firmId: _vm.candidateDetail.firmId,
                  isNeedComplete: _vm.isNeedEditBaseInfo,
                },
                on: {
                  "recommend-confirm": _vm.showRecommendConfirmDialog,
                  "recommend-cancel": function ($event) {
                    return _vm.goToUrl(`/#/Job/${_vm.jobId}`, false)
                  },
                  "recommend-end": _vm.recommendEnd,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.isRecommended && !_vm.candidateLoading
        ? _c(
            "div",
            { staticClass: "recommend-done-tip" },
            [
              _c("p", { staticClass: "tip-context" }, [
                _vm._v("\n      " + _vm._s(_vm.recommendedMessage) + "\n    "),
              ]),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.goToUrl(`/#/Job/${_vm.jobId}`, false)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("job-match-continue-dialog", {
        ref: "jobMatchContinueDialog",
        attrs: {
          candidateName: _vm.candidateDetail.realName,
          jobDetail: _vm.jobDetail,
        },
      }),
      _c("base-info-edit-dialog", {
        ref: "baseInfoEditDialog",
        attrs: { isRecommend: true },
        on: { "candidate-detail-refresh": _vm.getCandidateDetail },
      }),
      _c("recommend-confirm-dialog", {
        ref: "recommendConfirmDialog",
        attrs: {
          candidateDetail: _vm.candidateDetail,
          jobDetail: _vm.jobDetail,
        },
        on: {
          "recommend-candidate": _vm.recommendCandidate,
          "recommend-cancel": function ($event) {
            _vm.recommendLoading = false
          },
        },
      }),
      _c(
        "el-dialog",
        {
          ref: "userInfoFillDialog",
          staticClass: "has-no-title",
          attrs: { visible: _vm.userInfoFillDialogVisible, width: "500px" },
          on: {
            "update:visible": function ($event) {
              _vm.userInfoFillDialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "recommend-confirm" }, [
            _vm._v("\n        请到\n        "),
            _c("span", { staticClass: "text-primary" }, [
              _vm._v("【我的资料】"),
            ]),
            _vm._v("\n        页面补充你的\n        "),
            _c("span", { staticClass: "text-primary" }, [
              _vm._v("【个人头像】"),
            ]),
            _vm._v("\n        和\n        "),
            _c("span", { staticClass: "text-primary" }, [_vm._v("【职位】")]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.userInfoFillDialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.goToUrl(
                        "/#/personalCenter?Tab=BasicInfo",
                        true
                      )
                    },
                  },
                },
                [_vm._v("去补充")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          ref: "needEditDialog",
          staticClass: "need-edit-dialog",
          attrs: {
            title: "提示",
            visible: _vm.needEditDialogVisible,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.needEditDialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "need-edit-wrap" }, [
            _c(
              "ul",
              { staticClass: "need-list" },
              _vm._l(_vm.needEditArr, function (item, index) {
                return _c(
                  "li",
                  { key: index, attrs: { title: item + "不完整或无效" } },
                  [_vm._v(_vm._s(item) + "不完整或无效")]
                )
              }),
              0
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleNeedEditClick },
                },
                [_vm._v("编辑")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }