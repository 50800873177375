<template>
  <div class="recommend-form">
        <!--hr直招推荐不需要上传附件 -->
        
        <el-upload
            v-if="!['3', '5'].includes(marketJobType)"
            class="attachment-upload"
            ref="attachmentUpload"
            action="https://jsonplaceholder.typicode.com/posts/"
            :file-list="fileList"
            :auto-upload="false"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-change="handleFileListChange"
            :http-request="handleExcelUpload"
            :on-success="handleUploadSuccess"
        >
            <font-icon href="#icon-upload-copy"></font-icon>
            上传推荐附件
            <!-- <span class="text-grey">（仅简历接收者可见）</span> -->
            <div slot="tip" class="el-upload__tip">
                <!-- <p class="text-grey">
                    <el-checkbox v-model="showAttach" style="margin-right: 6px;"></el-checkbox>
                    在简历详情页中隐藏，仅对接收者展示
                </p> -->
                <!-- <p class="text-grey">如果不希望简历接受者看到候选人联系方式，请在附件中去掉联系方式</p> -->
                <!-- <p class="text-red" v-text="tipWord">当前该候选人简历中没有附件，请添加附件</p> -->
                <p class="text-grey visible-range-tip">
                    <span>可见范围：团队leader，内控及管理员可见</span>
                    <el-tooltip class="item" effect="light" content="候选人被淘汰或完成入职到保证期后，附件将公开" placement="top">
                        <font-icon class="tip-icon" href="#icon-warning-circle"></font-icon>
                    </el-tooltip>
                </p>
            </div>
        </el-upload>
        <el-alert
            v-if="!['3', '5'].includes(marketJobType)"
            title="仅支持上传pdf、doc、docx、txt格式的简历(不支持pdf格式的图片)"
            type="info"
            class="file-tip"
            show-icon
            close-text="知道了">
        </el-alert>
        <!-- :closable="false" -->
        <div class="recommend-reason">
            <h4 class="recommend-reason-title">
                推荐原因
            </h4>
            <el-form
                ref="recommendReasonForm"
                class="recommend-reason-form"
                :rules="recommendReasonFormRules"
                :model="recommendReasonForm"
            >
                <el-form-item prop="RecommendReason">
                    <el-input
                        type="textarea"
                        :rows="6"
                        placeholder="填写对候选人的评价和推荐评语，候选人的职业预期，期望薪资等"
                        v-model="recommendReasonForm.RecommendReason"
                    ></el-input>
                </el-form-item>
                <!-- <el-form-item>
                    <el-button type="primary" @click="recommendConfim">推荐</el-button>
                    <el-button @click="recommendCancel">取消</el-button>
                </el-form-item> -->
            </el-form>
        </div>
        <div class="recommend-btn">
            <el-button type="primary" @click="recommendConfim">推荐</el-button>
            <el-button @click="recommendCancel">取消</el-button>
        </div>
  </div>
</template>

<script>
import { recommend as recommendUrl } from '@src/js/config/api.json';
export default {
    props: {
        candidateId: {
            type: String,
            default: ''
        },
        firmId: {
            type: String,
            default: '',
        },
        isNeedComplete: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            recommendReasonForm: {
                RecommendReason: ""
            },
            recommendReasonFormRules: {
                RecommendReason: [
                    {   required: true, message: "请填写推荐原因", trigger: ['change', 'blur']  },
                    {   min: 20, message: '推荐原因请至少填写20个字符', trigger: ['change', 'blur']  },
                ]
            },
            fileList: [],
            recommendationId: "",
            showAttach: false, // 是否隐藏简历附件
            tipWord: '',
        }
    },
    computed: {
        isCFUser() {
            return this.$store.state.user.userInfo.isCFUser;
        },
        userFirmId() {
            return this.$store.state.user.userInfo.firmId;
        },
        marketJobType() {
            if(this.$route.query.marketJobType == 'undefined') {
                return null;
            } else {
                return this.$route.query.marketJobType;
            }
        },
    },
    methods: {
        submitUpload(recommendationId) {
            if(recommendationId && this.fileList.length > 0) {
                this.recommendationId = recommendationId;
                this.$refs.attachmentUpload.submit();
            } else {
                this.$emit('recommend-end');
            }
        },
        handleRemove(file, fileList) {
            this.hasResumeAttach();
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        handleFileListChange(file, fileList) {
            let fileName = file.name.slice(0, file.name.lastIndexOf("."));
            let extArr = file.name.split(".");
            let ext = extArr[extArr.length -1];
            if(!["pdf", "doc", "docx", "txt"].includes(ext)) {
                shortTips("文件类型不符合要求！");
                this.$refs.attachmentUpload.uploadFiles = [];
                return;
            }
            if(fileName.length > 200) {
                shortTips("文件名长度不能超过200！");
                this.$refs.attachmentUpload.uploadFiles = [];
                return;
            }
            if(file.size / 1024 / 1024 > 2) {
                shortTips("文件大小不得超过2M");
                this.$refs.attachmentUpload.uploadFiles = [];
                return;
            }
            this.fileList = [];
            this.fileList.push(file);

            // 判断附件是否重复
            if(!this.candidateId && this.firmId == this.userFirmId) return;
            let formData = new FormData();
            this.fileList.map((el, index) => {
                if (el.raw) {
                    formData.append(`file`, el.raw);
                }
            });
            formData.append(`candidateId`, this.candidateId);
            formData.append('__RequestVerificationToken', this.$store.state.verificationToken.verificationToken);
            _request({
                method: "POST",
                url: recommendUrl.check_candidate_attachment_hash_info.replace(/\%p/g, this.candidateId),
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: formData,
                throwBusinessError: true
            }).then(res => {
                this.tipWord = '';
            }).catch(err => {
                if(err.message.indexOf("简历附件重复") > -1) {
                    this.tipWord = '经检测，该附件与已有附件高度相似度很高，上传后将不做展示。';
                }
            });
        },
        handleExcelUpload() {
            let formData = new FormData();
            this.fileList.map((el, index) => {
                if (el.raw) {
                    formData.append(`file`, el.raw);
                }
            });
            formData.append(`recommendationId`, this.recommendationId);
            formData.append('__RequestVerificationToken', this.$store.state.verificationToken.verificationToken);
            _request({
                method: 'POST',
                url: recommendUrl.recommendation_attachment_add.replace(/\%p/g, this.recommendationId),
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: formData,
                throwBusinessError: true
            }).then(res => {
                this.$emit('recommend-end');
            }).catch(err => {
                // 简历附件重复
                if(err.message.indexOf('简历附件重复') > -1) {
                    this.tipWord = '经检测，该附件与已有附件高度相似度很高，上传后将不做展示。';
                    shortTips("简历附件重复");
                } else {
                    shortTips("推荐附件上传失败");
                }
                this.$emit('recommend-end');
            });
        },
        handleUploadSuccess(response, file, fileList) {
            this.fileList = fileList;
            if (response.succeeded) {}
        },
        recommendConfim() {
            if (this.isNeedComplete) {
                document.querySelector('.resume-base-info .base-info-tip')
                && document.querySelector('.resume-base-info .base-info-tip').scrollIntoView({
                    block: 'center'
                })
                return false;
            }

            this.$parent.showFromTip = (this.$parent.jobFrom == null);
            if(this.isCFUser) {
                this.$parent.showBusinessTip = (this.$parent.BusinessClassificationId == '');
            }
            this.$parent.showCityTip = (this.$parent.locations.length == 0);
            if(this.$parent.jobFrom == null) return shortTips(`请选择渠道来源`);
            if(this.$parent.jobFrom == '其它' && this.$parent.otherContext == '') {
                return shortTips(`请输入渠道来源`);
            }
            if(this.isCFUser && this.$parent.BusinessClassificationId == '') return shortTips(`请选择业务分类`);
            if(this.$parent.locations.length == 0) return shortTips(`请选择推荐城市`);
            if(this.tipWord == '当前该候选人简历中没有附件，请添加附件。' && !['3', '5'].includes(this.marketJobType)) {
                return shortTips(`请添加候选人推荐附件`);
            }
            this.$refs.recommendReasonForm.validate((validator) => {
                if(validator) {
                    this.$emit('recommend-confirm', this.recommendReasonForm);
                }
            });
        },
        recommendCancel() {
            this.$confirm("确认退出推荐？", {
                customClass: "recommend-cancel-dialog",
                type: "warning",
                showClose: false,
                cancelButtonText: "否，继续推荐",
                confirmButtonText: "退出",
                callback: (action, instance) => {
                    if(action == 'confirm') {
                        this.$emit('recommend-cancel');
                    }
                }
            });
        },
        // 判断是否有简历附件
        hasResumeAttach() {
            if(!this.candidateId) return;
            _request({
                method: "GET",
                url: recommendUrl.has_resume_attach.replace(/\%p/g, this.candidateId),
            }).then(res => {
                if(!res) {
                    this.tipWord = '当前该候选人简历中没有附件，请添加附件。';
                } else {
                    this.tipWord = '';
                }
            }).catch(err => {
            });
        },
    },
    created() {
        if (this.isCFUser) {
            this.hasResumeAttach();
        }
    },
}
</script>

<style lang="scss" scope>
.recommend-form {
    .attachment-upload {
        color: $primary;
        position: relative;
        line-height: 32px;
        .font-icon {
            width: 16px;
            height: 16px;
            margin-right: 5px;
        }
        .el-upload__tip{
            .visible-range-tip {
                margin-bottom: 10px;
                font-size: 14px;
                span {
                    margin-right: 4px;
                    vertical-align: middle;
                }
                .tip-icon {
                    cursor: pointer;
                }
            }
            >p{
                margin: 0;
                line-height: 14px;
                font-size: 12px;
            }
            .text-grey {
                color: #999;
            }
            .text-red {
                color: red;
                margin-bottom: 4px;
            }
        }
        .el-upload__input {
            display: none;
        }
        .el-upload-list {
            // display: inline-block;
            vertical-align: middle;
            max-width: 600px;
            // margin-left: 7px;
            // position: absolute;
            // top: 0;
            // left: 330px;
            &:focus {
                outline: none;
            }
            .el-upload-list__item {
                margin-top: 0;
                line-height: 32px;
                &:focus {
                    outline: none;
                }
                .el-upload-list__item-name {
                    color: $primary;
                    cursor: default;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 830px;
                    .el-icon-document::before {
                        content: "\e77d";
                    }
                }
                .el-icon-close {
                    top: 10px;
                    color: $primary;
                }
            }
        }
    }
    .file-tip{
        color: #67c23a;
        background: #f0f9eb;
        width: 600px;
        margin-top: 10px;
    }
    .recommend-reason {
        // margin-top: 16px;
        margin-top:20px;
        display: flex;
        .recommend-reason-title {
            width: 80px;
            // font-size: 16px;
            font-size: 18px;
            margin-bottom: 20px;
            margin-right: 10px;
            color: #333;
        }
        .recommend-reason-form {
            width: 100%;
            .el-form-item {
                .el-checkbox {
                    margin-bottom: 0;
                    .el-checkbox__inner {
                        width: 18px;
                        height: 18px;
                        &::after {
                            width: 4px;
                            height: 10px;
                            left: 5px;
                            border-width: 2px;
                        }
                    }
                }
                &:nth-of-type(2) {
                    .el-form-item__content {
                        line-height: 24px;
                    }
                }
            }
        }
    }
    .recommend-btn {
        margin-top: 20px;
    }
}
</style>

<style lang="scss">
.el-message-box.recommend-cancel-dialog {
    .el-message-box__header {
        border-bottom: none;
    }
}
</style>