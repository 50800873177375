var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "recommend-form" },
    [
      !["3", "5"].includes(_vm.marketJobType)
        ? _c(
            "el-upload",
            {
              ref: "attachmentUpload",
              staticClass: "attachment-upload",
              attrs: {
                action: "https://jsonplaceholder.typicode.com/posts/",
                "file-list": _vm.fileList,
                "auto-upload": false,
                "on-preview": _vm.handlePreview,
                "on-remove": _vm.handleRemove,
                "on-change": _vm.handleFileListChange,
                "http-request": _vm.handleExcelUpload,
                "on-success": _vm.handleUploadSuccess,
              },
            },
            [
              _c("font-icon", { attrs: { href: "#icon-upload-copy" } }),
              _vm._v("\n          上传推荐附件\n          "),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _c(
                    "p",
                    { staticClass: "text-grey visible-range-tip" },
                    [
                      _c("span", [
                        _vm._v("可见范围：团队leader，内控及管理员可见"),
                      ]),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "light",
                            content:
                              "候选人被淘汰或完成入职到保证期后，附件将公开",
                            placement: "top",
                          },
                        },
                        [
                          _c("font-icon", {
                            staticClass: "tip-icon",
                            attrs: { href: "#icon-warning-circle" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      !["3", "5"].includes(_vm.marketJobType)
        ? _c("el-alert", {
            staticClass: "file-tip",
            attrs: {
              title:
                "仅支持上传pdf、doc、docx、txt格式的简历(不支持pdf格式的图片)",
              type: "info",
              "show-icon": "",
              "close-text": "知道了",
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "recommend-reason" },
        [
          _c("h4", { staticClass: "recommend-reason-title" }, [
            _vm._v("\n              推荐原因\n          "),
          ]),
          _c(
            "el-form",
            {
              ref: "recommendReasonForm",
              staticClass: "recommend-reason-form",
              attrs: {
                rules: _vm.recommendReasonFormRules,
                model: _vm.recommendReasonForm,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "RecommendReason" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 6,
                      placeholder:
                        "填写对候选人的评价和推荐评语，候选人的职业预期，期望薪资等",
                    },
                    model: {
                      value: _vm.recommendReasonForm.RecommendReason,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.recommendReasonForm,
                          "RecommendReason",
                          $$v
                        )
                      },
                      expression: "recommendReasonForm.RecommendReason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "recommend-btn" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.recommendConfim } },
            [_vm._v("推荐")]
          ),
          _c("el-button", { on: { click: _vm.recommendCancel } }, [
            _vm._v("取消"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }