import { business_classification as businessClassificationUrl } from '#/js/config/api.json';

export default {
    // 获取用户权限业务分类设置列表
    getBusinessClassificationManageList(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: businessClassificationUrl.get_business_classification_manage_list,
            data: params
        });
    },

    // 获取业务分类列表
    getBusinessClassificationList(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: businessClassificationUrl.get_business_classification_list,
            data: params
        });
    },
    
    // 创建业务分类
    createBusinessClassification(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: businessClassificationUrl.business_classification_create,
            data: params
        });
    },

    // 编辑业务分类
    editBusinessClassification(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: businessClassificationUrl.business_classification_edit,
            data: params
        });
    },

    // 排序
    sortBusinessClassification(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: businessClassificationUrl.business_classification_sort,
            data: params
        });
    },

    // 合并业务分类
    megeBusinessClassification(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: businessClassificationUrl.business_classification_merge,
            data: params
        });
    },

    // 启用禁用业务分类
    updateStatus(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: businessClassificationUrl.business_classification_update_status,
            data: params
        });
    },

    // 我的推荐、offer列表中编辑所属业务分类
    updateBusinessClassification(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: businessClassificationUrl.update_business_classification.replace(/\%p/ig, params.RecommendationId),
            data: params
        });
    }
};