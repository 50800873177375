<template>
    <div class="candidate-resume-view">
        <div class="resume-view-title">
            <span>
                简历预览
            </span>
            <span v-if="!candidateDetail.isFromAuthorization" class="resume-view-title-tip">
                <i class="point-tip" v-if="$store.state.user.userInfo.isCFUser">更新简历有奖哦，新增候选人最新雇主公司和职位可得15积分</i>
                <a
                    class="text-operate-btn"
                    :href="`/Headhunting/MyCompany.html?type=1&candidateId=${candidateDetail.id}#/resumeUpload/singleUpload`"
                    target="_blank"
                >
                    <i class="el-icon-edit-outline"></i>
                    编辑
                </a>
            </span>
        </div>
        <div class="resume-view-container">
            <div class="resume-base-info">
                <h3 class="candidate-realname">{{candidateDetail.realName}}</h3>
                <p class="candidate-info">
                    {{candidateDetail.company}} {{candidateDetail.title}}
                </p>
                <p class="candidate-info">
                    {{candidateDetail.degreeInfo}}
                    <span v-if="candidateDetail.degreeInfo && candidateDetail.yearOfExperience">|</span>
                    {{candidateDetail.yearOfExperience}}
                </p>
                <div class="candidate-info info-with-icon">
                    <font-icon href="#icon-message"></font-icon> {{candidateDetail.email ? candidateDetail.email : '******'}}
                    <font-icon href="#icon-mobile"></font-icon> {{candidateDetail.mobile ? candidateDetail.mobile : '******'}}
                    <template v-if="candidateDetail.provinceName || candidateDetail.cityName">
                        <font-icon href="#icon-address-copy"></font-icon> {{candidateDetail.provinceName}} {{candidateDetail.cityName}}
                    </template>
                </div>
                <div class="candidate-info view-tip">
                    <span class="view-tip-icon">匿</span>手机和电话会被隐藏，安排面试后HR才能看见
                </div>
                <div class="base-info-tip" v-if="isNeedComplete && needEditStr && needEditArr.length > 0">
                    <el-popover
                        placement="bottom"
                        title=""
                        width="300"
                        trigger="hover"
                        popper-class="need-edit-wrap-popover"
                    >
                        <span slot="reference">
                            <span v-if="needEditStr.indexOf('重要信息') > -1">简历存在基本信息不完整等{{needEditArr.length}}个问题，请补充<i class="el-icon-d-arrow-right"></i></span>
                            <span v-else-if="needEditStr.indexOf('最近工作经历') > -1">简历存在最近工作经历不完整等{{needEditArr.length}}个问题，请补充<i class="el-icon-d-arrow-right"></i></span>
                            <span v-else-if="needEditStr.indexOf('最近教育经历') > -1">简历存在最近教育经历不完整等{{needEditArr.length}}个问题，请补充<i class="el-icon-d-arrow-right"></i></span>
                            <span v-else>简历存在信息不完整等{{needEditArr.length}}个问题，请补充<i class="el-icon-d-arrow-right"></i></span>
                            <!-- <el-button class="complete-btn" type="primary" @click="editBaseInfo">编辑</el-button> -->
                        </span>
                        <div class="need-edit-wrap">
                            <ul class="need-list">
                                <li v-for="(item, index) in needEditArr" :key="index" :title="item + '不完整或无效'">{{item}}不完整或无效</li>
                            </ul>
                            <el-button type="primary" v-if="!candidateDetail.isFromAuthorization" @click="handleNeedEditClick">编辑</el-button>
                        </div>
                    </el-popover>
                </div>
            </div>
            <div class="resume-content">
                <h4 class="resume-content-title">个人简历</h4>
                <div class="resume-detail" v-html="changeContent(candidateDetail.content)"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        candidateDetail: Object,
        isNeedComplete: Boolean,
        needEditArr: Array,
        needEditStr: String
    },
    data() {
        return {}
    },
    methods: {
        changeContent(context) {
            return context ? context.replace(/\n/g, '<br/>') : "";
        },
        handleNeedEditClick() {
            if (this.needEditStr.indexOf('最近工作经历') > -1 || this.needEditStr.indexOf('最近教育经历') > -1) {
                sessionStorage.setItem('editResumeBackRecommendUrl', location.href);
                let url = `/Headhunting/MyCompany.html?type=1&candidateId=${this.candidateDetail.id}&isRecommend=1#/resumeUpload/singleUpload`;
                // window.open(url,'_blank');
                location.href = url;
            } else {
                this.editBaseInfo();        
            }
        },
        editBaseInfo() {
            this.$emit('edit-base-info');
        }
    }
}
</script>

<style lang="scss" scope>
.candidate-resume-view {
    padding: 0 30px;
    .resume-view-title {
        font-size: 16px;
        color: #666;
        // color: #333;
        // margin-bottom: 15px;
        margin: 30px 0;
        line-height: 27px;
        display: flex;
        // justify-content: space-between;

        &-tip {
            margin-left: 20px;
            .text-operate-btn {
                &:hover {
                    text-decoration: none;
                }
            }
            .el-icon-edit-outline {
                color: #40BC9D;
            }
        }

        .point-tip {
            font-size: 14px;
            color: #EE6E4F;
            margin-right: 10px;
        }

        .resume-edit-btn {
            cursor: pointer;
            font-size: 14px;
            color: #999;
        }
    }
    .resume-view-container {
        // background-color: #f5fbfa;
        // padding: 30px 20px;
        padding-bottom: 20px;
        color: #777;
        .resume-base-info {
            line-height: 24px;
            padding-bottom: 10px;
            border-bottom: 1px solid #f1f1f1;
            .candidate-realname {
                color: #526069;
                font-size: 24px;
                margin-bottom: 10px;
            }
            .candidate-info {
                margin-bottom: 10px;
            }
            .base-info-tip {
                color: #FF6564;
                .complete-btn {
                    margin-left: 15px;
                }
            }
            .info-with-icon {
                font-size: 12px;
                color: #a0a0a0;
                .font-icon {
                    width: 12px;
                    height: 12px;
                }
            }
            .view-tip {
                .view-tip-icon {
                    color: #6c6;
                    border: 1px solid #6c6;
                    border-radius: 2px;
                    padding: 0 4px;
                    margin-right: 5px;
                }
            }
        }
        .resume-content {
            margin-top: 20px;
            .resume-content-title {
                font-size: 16px;
                margin-bottom: 7px;
            }
            .resume-detail {
                white-space: pre-wrap;
            }
        }
    }
}
</style>

<style lang="scss">
.complete-btn.el-button.el-button--primary {
    padding: 0;
    min-width: 40px;
}

.need-edit-wrap-popover {
    .need-edit-wrap {
        padding: 10px;

        .need-list li {
            font-size: 14px;
            color: #666;
            line-height: 28px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .el-button {
            margin-top: 12px;
            float: right;
        }
    }
}
</style>